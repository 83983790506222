import { Component } from '@angular/core';

@Component({
  selector: 'app-assertdepression',
  templateUrl: './assertdepression.component.html',
  styleUrls: ['./assertdepression.component.css']
})
export class AssertdepressionComponent {

}
