import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  private _baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getStats(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/assert/stats`);
  }

  getStationStats(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/station/stats`);
  }


  getAllStationStats(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/station/stationsStat`);
  }

  getToken(): Observable<any> {
    return this.http.get<{ token: string }>(`${environment.apiUrl}/assert/statsToken`);
  }

  downloadPdf(): Observable<Blob> {
    return this.getToken().pipe(
      switchMap(tokenData => {
        const token = tokenData.token;
        return this.http.get(`${environment.apiUrl}/api/v1/jasper/pdf/asset?token=${token}`, { responseType: 'blob' });
      })
    );
  }

}
