<p-toast></p-toast>
<app-sidebar></app-sidebar>
<section class="home-section">


  <!-- nav bar -->
  <nav class="navbar navbar-expand-md breadcrumb">
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item-left">

        </li>

        <li class="nav-item move-right mr-3">
          <a class="nav-link" data-toggle="tab" href="#profile">
            Welcome, <i class="fa fa-user"></i>
          </a>
        </li>
      </ul>

    </div>
  </nav>
