import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';

import { User } from '../model/user';

import { BehaviorSubject, catchError, map, Observable, of, startWith, switchMap, tap, throwError } from 'rxjs';
import { AccountType, CustomHttpResponse, Profile, VerifySate } from '../interface/appstates';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Stock } from '../model/stock';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Key } from '../enum/key.enum';
import { DataState } from "../enum/datastate.enum";
import { ParamMap } from "@angular/router";
const baseUrl = `${environment.apiUrl}/user`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  updateRoles$ //   this.http.delete<any>(`${this.server}/user/delete/${user.id}`)
    (roleName: any) {
      throw new Error('Method not implemented.');
  }
  verifyState$: Observable<VerifySate>;
  private userSubject = new BehaviorSubject<User>(null!);
  user$ = this.userSubject.asObservable();
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  readonly DataState = DataState;
  private readonly ACCOUNT_KEY: string = 'key';


  private jwtHelper = new JwtHelperService();
  private server = environment.apiUrl;
  private tokenSubject: BehaviorSubject<string | null>;
  public currentUser: Observable<User>;
  accessToken: any;
  
 
  activatedRoute: any;
  userService: any;
  constructor(private http: HttpClient) {
    const tokenn: any = localStorage.getItem(Key.REFRESH_TOKEN);
    this.tokenSubject = new BehaviorSubject<string | null>(this.getToken());
  }
  ngOnInit(): void {
    this.verifyState$ = this.activatedRoute.paramMap.pipe(
      switchMap((params: ParamMap) => {
        console.log(this.activatedRoute);
        const type: AccountType = this.getAccountType(window.location.href);
        return this.userService.verify$(params.get(this.ACCOUNT_KEY), type)
          .pipe(
            map((response:any) => {
              console.log(response);
              type === 'password' ? this.userSubject.next(response.data.user) : null;
              return { type, title: 'Verified!', dataState: DataState.LOADED, message: response.message, verifySuccess: true };
            }),
            startWith({ title: 'Verifying...', dataState: DataState.LOADING, message: 'Please wait while we verify the information', verifySuccess: false }),
            catchError((error: string) => {
              return of({ title: error, dataState: DataState.ERROR, error, message: error, verifySuccess: false })
            })
          )
      })
    );
  }
  getAccountType(href: string): AccountType {
    throw new Error("Method not implemented.");
  }


  public getStock(): Observable<Stock[] | HttpErrorResponse> {
    return this.http.get<Stock[]>('${this.host}/stock/list');
  }

  public fetchNotifications(user: any) {
    return this.http.post<any>(this.server + '/stock/browseNotifications/' + user.receiverId, user);
  }
  createUserFormDate(data: any, test: any, tsts: any) {
    return [];
  }
  public addUser(formData: any): Observable<Stock | HttpErrorResponse> {
    return this.http.post<Stock>(`${this.server}/user/add`, formData);

  }
  getAllUsers(): Observable<any> {
    return this.http.get<any>(baseUrl + '/list');
  }

  login$ = (email: string, password: string) =>
    <Observable<CustomHttpResponse<Profile>>>this.http
      .post<CustomHttpResponse<Profile>>(`${this.server}/user/login`, {
        email,
        password,
      })
      .pipe(
        tap((response: any) => {
          this.accessToken = response.data.access_token;

          this.setToken(response.data.access_token);



        }),
        catchError(this.handleError)
      );

  verifyCode$ = (email: any, code: any) => <Observable<CustomHttpResponse<Profile>>>
    this.http.get<CustomHttpResponse<Profile>>
      (`${this.server}/user/verify/code/${email}/${code}`)
      .pipe(
        tap(console.log),
        catchError(this.handleError)
      );
  getToken(): string | null {
    return localStorage.getItem('token');
  }

  verify$ = (key: any, type: AccountType) => <Observable<CustomHttpResponse<Profile>>>
    this.http.get<CustomHttpResponse<Profile>>
      (`${this.server}/user/verify/${type}/${key}`)
      .pipe(
        tap(console.log),
        catchError(this.handleError)
      );


  

      

  setToken(token: string): void {
    localStorage.setItem('token', token);
    this.tokenSubject.next(token);
  }
  save$ = (user: User) => <Observable<CustomHttpResponse<Profile>>>
    this.http.post<CustomHttpResponse<Profile>>
      (`${this.server}/user/register`, user)
      .pipe(
        tap(console.log),
        catchError(this.handleError)
      );

  requestPasswordReset$ = (email: string) => <Observable<CustomHttpResponse<Profile>>>
    this.http.get<CustomHttpResponse<Profile>>
      (`${this.server}/user/resetpassword/${email}`, { observe: 'response' })
      .pipe(
        tap(console.log),
        catchError(this.handleError)
      );

  singleProfile$ = (id: any) => <Observable<CustomHttpResponse<Profile>>>
    this.http.get<CustomHttpResponse<Profile>>
      (`${this.server}/user/users/${id}`)
      .pipe(
        tap(console.log),
        catchError(this.handleError)
      );

  profile$ = () => <Observable<CustomHttpResponse<Profile>>>
    this.http.get<CustomHttpResponse<Profile>>
      (`${this.server}/user/profile`)
      .pipe(
        tap(console.log),
        catchError(this.handleError)
      );

  update$ = (user: User) => <Observable<CustomHttpResponse<Profile>>>
    this.http.patch<CustomHttpResponse<Profile>>
      (`${this.server}/user/update`, user)
      .pipe(
        tap(console.log),
        catchError(this.handleError)
      );

      updateAuthorization$=(userId:number,role:string)=> <Observable<CustomHttpResponse<any>>>
      this.http.get<CustomHttpResponse<Profile>>
        (    `${this.server}/api/v1/admin/roles/update?userId=${userId}&role=${role}`)
        .pipe(
          tap(console.log),
          catchError(this.handleError)
        );
  
  delete$(user: any) {
    let url = `${this.server}/user/delete/${user.id}`;
    return this.http.delete<any>(url);
  }

  // delete$ = (user: any) => <Observable<any>
  //   this.http.delete<any>(`${this.server}/user/delete/${user.id}`)
  //   .pipe(
  //     tap(console.log),
  //     catchError(this.handleError)
  //   );

  refreshToken$ = () => <Observable<CustomHttpResponse<Profile>>>this.http
      .get<CustomHttpResponse<Profile>>(`${this.server}/user/refresh/token`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(Key.REFRESH_TOKEN)}`,
        },
      })
      .pipe(
        tap((response: any) => {
          this.setToken(response.data.access_token);

          console.log(response);
          localStorage.removeItem(Key.TOKEN);
          localStorage.removeItem(Key.REFRESH_TOKEN);
          localStorage.setItem(Key.TOKEN, response.data.access_token);
          localStorage.setItem(Key.REFRESH_TOKEN, response.data.refresh_token);
        }),
        catchError(this.handleError)
      );
  getAuthToken(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }

  updatePassword$ = (form: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }) =>
    <Observable<CustomHttpResponse<Profile>>>(
      this.http
        .patch<CustomHttpResponse<Profile>>(
          `${this.server}/user/update/password`,
          form
        )
        .pipe(tap(console.log), catchError(this.handleError))
    );






  // updateRoles$ = (roleName: string) => <Observable<CustomHttpResponse<Profile>>>
  //   this.http.patch<CustomHttpResponse<Profile>>
  //     (`${this.server}/user/update/role/${roleName}`, {})
  //     .pipe(
  //       tap(console.log),
  //       catchError(this.handleError)
  //     );




  updateAccountSettings$ = (settings: {
    enabled: boolean;
    notLocked: boolean;
  }) =>
    <Observable<CustomHttpResponse<Profile>>>(
      this.http
        .patch<CustomHttpResponse<Profile>>(
          `${this.server}/user/update/settings`,
          settings
        )
        .pipe(tap(console.log), catchError(this.handleError))
    );

  toggleMfa$ = () =>
    <Observable<CustomHttpResponse<Profile>>>(
      this.http
        .patch<CustomHttpResponse<Profile>>(`${this.server}/user/togglemfa`, {})
        .pipe(tap(console.log), catchError(this.handleError))
    );

  updateImage$ = (formData: FormData) =>
    <Observable<CustomHttpResponse<Profile>>>(
      this.http
        .patch<CustomHttpResponse<Profile>>(
          `${this.server}/user/update/image`,
          formData
        )
        .pipe(tap(console.log), catchError(this.handleError))
    );

  logOut() {
    localStorage.removeItem(Key.TOKEN);
    localStorage.removeItem(Key.REFRESH_TOKEN);
  }

  isAuthenticated = (): boolean =>
    this.jwtHelper.decodeToken<string> &&
    !this.jwtHelper.isTokenExpired(localStorage.getItem(Key.TOKEN))
      ? true
      : false;

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.log(error);
    // let errorMessage: string;
    // if (error.error instanceof ErrorEvent) {
    //   errorMessage = `A client error occurred - ${error.error.message}`;
    // } else {
    //   if (error.error.reason) {
    //     errorMessage = error.error.reason;
    //     console.log(errorMessage);
    //   } else {
    //     errorMessage = `An error occurred - Error status ${error.status}`;
    //   }
    // }
    return throwError(() => error);
  }

  getAllStations(): Observable<any> {
    return this.http.get<any>(`${this.server}/station/getAll`);
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${this.server}/api/v1/admin/roles/all`);
  }
  updateStationToUser(userId: number, stationId: number|undefined): Observable<any> {
    return this.http.post<any>(
      // `${this.server}/user/` + userId + `/assignStation/` + stationId,
      // ''
      `${this.server}/api/v1/user/station/add?userId=`+userId+`&stationId=`+stationId,''
    );
  }

  removeStationToUser(stationId: number|undefined): Observable<any> {
    return this.http.delete<any>(
      `${this.server}/api/v1/user/station/remove?assignId=`+stationId,
    );
  }

  getAllStationsOfuser(userId:number): Observable<any> {
    return this.http.get<any>(`${this.server}/api/v1/user/station/getUser?userId=`+userId);
  }

}
