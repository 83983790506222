import { Component } from '@angular/core';

@Component({
  selector: 'app-new-head-procurement-authorisation',
  templateUrl: './new-head-procurement-authorisation.component.html',
  styleUrls: ['./new-head-procurement-authorisation.component.css']
})
export class NewHeadProcurementAuthorisationComponent {

}
