import { Component } from '@angular/core';

@Component({
  selector: 'app-officeb',
  templateUrl: './officeb.component.html',
  styleUrls: ['./officeb.component.css']
})
export class OfficebComponent {
checks: any[];

}
