import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSerializer, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service/authentication.service';
import { UserService } from '../service/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {

  constructor(
    private router: Router,
    private userSvc: UserService
) { }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
boolean | Observable<boolean> | Promise<boolean> {
  console.log(this.userSvc.getAuthToken());
  if (!this.userSvc.getAuthToken()) {
    this.router.navigate(['login']);
  }
  
  return true;
}

canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
boolean | Observable<boolean> | Promise<boolean> {
  return this.canActivate(route, state);
}

 

 // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     
  }
