import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';

import {
  Observable,
  of,
  BehaviorSubject,
  map,
  startWith,
  catchError,
} from 'rxjs';
import { DataState } from 'src/app/enum/datastate.enum';
import { EventType } from 'src/app/enum/event-type.enum';
import { Key } from 'src/app/enum/key.enum';
import { CustomHttpResponse, Profile } from 'src/app/interface/appstates';
import { State } from 'src/app/interface/state';
import { UserService } from 'src/app/service/user.service';
import { User } from '../model/user';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
interface Role {
  id: number;
  name: string;
  permission: string;
 }
 
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  profileState$: Observable<any>;
  singleprofileState$: Observable<State<CustomHttpResponse<Profile>>>;
  private dataSubject = new BehaviorSubject<any | null>(null);
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  private showLogsSubject = new BehaviorSubject<boolean>(false);
  showLogs$ = this.showLogsSubject.asObservable();
  readonly DataState = DataState;
  readonly EventType = EventType;
  user: User | undefined;
  userData: any;
  activeTab: any = 'profile';
  userId: any;
  selectedStation = null;
  stationList: any;
  userStations: any;
  profData: Profile;
  selectedRole: string = '';
  // roles: any;
  //lets remove this we take from backend
  
  roles:Role[]=[];
  constructor(
    private userService: UserService,
    // private toastr: ToastrService,
    private messageService: MessageService,
    private messageservice: MessageService,
    private router: ActivatedRoute
  ) {
    const userData: any = localStorage.getItem('user');
    this.userData = JSON.parse(userData);
  }
  ngOnInit(): void {
    this.userId = this.router.snapshot.paramMap.get('uid');
    console.log(this.userId);
    this.userService.getRoles().subscribe(roles => {
      this.roles = roles;
    });
    this.profileState$ = this.userService.profile$().pipe(
      map((response) => {
        console.log(response);
        this.dataSubject.next(response);
        return { dataState: DataState.LOADED, appData: response };
      }),
      startWith({ dataState: DataState.LOADING }),
      catchError((error: string) => {
        return of({
          dataState: DataState.ERROR,
          appData: this.dataSubject.value,
          error,
        });
      })
    );
    this.profileState$ = this.userService.singleProfile$(this.userId).pipe(
      map((response: any) => {
        console.log(response);
        response['roles'] = this.roles;
        this.dataSubject.next(response);
        return { dataState: DataState.LOADED, appData: response };
      }),
      startWith({ dataState: DataState.LOADING }),
      catchError((error: string) => {
        return of({
          dataState: DataState.ERROR,
          appData: this.dataSubject.value,
          error,
        });
      })
    );
    this.getAllStations();
    this.getUserStations();
  }

  getAllStations() {
    this.userService.getAllStations().subscribe((data) => {
      console.log(data);
      this.stationList = data;
    });
  }

  getUserStations() {
    this.userService.getAllStationsOfuser(this.userId).subscribe((data) => {
      console.log(data);
      this.userStations = data;
    });
  }
  getSelectedStationIdByName(stationName: any): number | undefined {
    const selectedStation = this.stationList.find((station: { name: string; }) => station.name === stationName);
    return selectedStation? selectedStation.id : undefined;
  }
  updateStation() {
    console.log(this.selectedStation);
    this.userService
      .updateStationToUser(this.userId, this.getSelectedStationIdByName(this.selectedStation))
      .subscribe((data) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Station updated successfully!', life: 3000 });
        this.getUserStations();
      },
      error => {
        console.error('Error updating station:', error);
       //  this.toastr.error(error?.data?.message, 'Failed to update.');
       this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail: error, life: 3000 });

      }
    );

  }

  removeStation(stationId:number){
    this.userService
      .removeStationToUser(stationId)
      .subscribe((data) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Station removed successfully!', life: 3000 });
        this.getUserStations();
      },
      error => {
        console.error('Error updating station:', error);
       //  this.toastr.error(error?.data?.message, 'Failed to update.');
       this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail: error, life: 3000 });

      }
    );
  }

  onChange(val: any) {
    console.log(val);
    this.activeTab = val;
  }

  updateProfile(profileForm: NgForm): void {
    console.log(profileForm);
    this.isLoadingSubject.next(true);
    this.profileState$ = this.userService.update$(profileForm.value).pipe(
      map((response) => {
        console.log(response);
        this.dataSubject.next({ ...response, data: response.data });
        this.isLoadingSubject.next(false);
        return { dataState: DataState.LOADED, appData: this.dataSubject.value };
      }),
      startWith({
        dataState: DataState.LOADED,
        appData: this.dataSubject.value,
      }),
      catchError((error: string) => {
        this.isLoadingSubject.next(false);
        return of({
          dataState: DataState.LOADED,
          appData: this.dataSubject.value,
          error,
        });
      })
    );
  }
  updatePassword(passwordForm: NgForm): void {
    this.isLoadingSubject.next(true);
    if (
      passwordForm.value.newPassword === passwordForm.value.confirmNewPassword
    ) {
      this.profileState$ = this.userService
        .updatePassword$(passwordForm.value)
        .pipe(
          map((response) => {
            this.messageservice.add({
              severity: 'success',
              summary: 'Successful',
              detail: response.message,
              life: 3000,
            });

            //   this.notification.onDefault(response.message);
            console.log(response);
            this.dataSubject.next({ ...response, data: response.data });
            passwordForm.reset();
            this.isLoadingSubject.next(false);
            return {
              dataState: DataState.LOADED,
              appData: this.dataSubject.value,
            };
          }),
          startWith({
            dataState: DataState.LOADED,
            appData: this.dataSubject.value,
          }),
          catchError((error: string) => {
            this.messageservice.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
              life: 3000,
            });
            passwordForm.reset();
            this.isLoadingSubject.next(false);
            return of({
              dataState: DataState.LOADED,
              appData: this.dataSubject.value,
              error,
            });
          })
        );
    } else {
      passwordForm.reset();
      this.isLoadingSubject.next(false);
    }
  }


 selectRole(roleName: string) {
  this.selectedRole=roleName;
}

updateAuthorization() {
  if (this.selectedRole) {
     this.userService.updateAuthorization$(this.userId, this.selectedRole).pipe(
     ).subscribe(
       response => {
         console.log('Authorization updated successfully:', response);
        //  this.toastr.error(response.data?.message, 'User Auth Updated.');
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Authorization updated successfully!', life: 3000 });
       },
       error => {
         console.error('Error updating authorization:', error);
        //  this.toastr.error(error?.data?.message, 'Failed to update.');
        this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail: 'Something went wrong while updating Authorization!', life: 3000 });

       }
     );
  }
 }
 

removeRole(roleName: string) {
  // this.roles = this.roles.filter(role => role.name !== roleName);
  alert("remove role");
}

  updateAccountSettings(settingsForm: NgForm): void {
    this.isLoadingSubject.next(true);
    this.profileState$ = this.userService
      .updateAccountSettings$(settingsForm.value)
      .pipe(
        map((response) => {
          console.log(response);
          this.dataSubject.next({ ...response, data: response.data });
          this.isLoadingSubject.next(false);
          return {
            dataState: DataState.LOADED,
            appData: this.dataSubject.value,
          };
        }),
        startWith({
          dataState: DataState.LOADED,
          appData: this.dataSubject.value,
        }),
        catchError((error: string) => {
          this.isLoadingSubject.next(false);
          return of({
            dataState: DataState.LOADED,
            appData: this.dataSubject.value,
            error,
          });
        })
      );
  }

  toggleMfa(): void {
    this.isLoadingSubject.next(true);
    this.profileState$ = this.userService.toggleMfa$().pipe(
      map((response) => {
        console.log(response);
        this.dataSubject.next({ ...response, data: response.data });
        this.isLoadingSubject.next(false);
        return { dataState: DataState.LOADED, appData: this.dataSubject.value };
      }),
      startWith({
        dataState: DataState.LOADED,
        appData: this.dataSubject.value,
      }),
      catchError((error: string) => {
        this.isLoadingSubject.next(false);
        return of({
          dataState: DataState.LOADED,
          appData: this.dataSubject.value,
          error,
        });
      })
    );
  }

  updatePicture(image: File): void {
    if (image) {
      this.isLoadingSubject.next(true);
      this.profileState$ = this.userService
        .updateImage$(this.getFormData(image))
        .pipe(
          map((response: any) => {
            console.log(response);
            this.dataSubject.next({
              ...response,
              data: {
                ...response.data,
                user: {
                  ...response.data.user,
                  imageUrl: `${
                    response.data.user.imageUrl
                  }?time=${new Date().getTime()}`,
                },
              },
            });
            this.isLoadingSubject.next(false);
            return {
              dataState: DataState.LOADED,
              appData: this.dataSubject.value,
            };
          }),
          startWith({
            dataState: DataState.LOADED,
            appData: this.dataSubject.value,
          }),
          catchError((error: string) => {
            this.isLoadingSubject.next(false);
            return of({
              dataState: DataState.LOADED,
              appData: this.dataSubject.value,
              error,
            });
          })
        );
    }
  }

  toggleLogs(): void {
    this.showLogsSubject.next(!this.showLogsSubject.value);
  }

  private getFormData(image: File): FormData {
    const formData = new FormData();
    formData.append('image', image);
    return formData;
  }
}