import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './guard/authentication.guard';
import { HeadProcurementAuthorisationComponent } from './component/head-procurement-authorisation/head-procurement-authorisation.component';
import { MinutesComponent } from './component/minutes/minutes.component'; 

import { NewHeadProcurementAuthorisationComponent } from './component/new-head-procurement-authorisation/new-head-procurement-authorisation.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';

import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { UserComponent } from './user/user.component';
import { VerifyComponent } from './user/verify/verify.component';
import { LogoutComponent } from './logout/logout.component';
import { AssettransferComponent } from './assert/assettransfer/assettransfer.component';
import { AssertdepressionComponent } from './assert/assertdepression/assertdepression.component';

import { ExternaltransferComponent } from './assert/externaltransfer/externaltransfer.component';
import { OfficebComponent } from './assert/officeb/officeb.component';
import { MasterregisterComponent } from './assert/masterregister/masterregister.component';
import { ManagementComponent } from './projectmanagement/management/management.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'Dashboard',
    component: UserComponent,
    canActivate: [AuthenticationGuard],
  },

  { path: 'logout', component: LogoutComponent },
  {
    path: 'resetpassword',
    component: ResetpasswordComponent
  },
  {
    path: 'user/verify/account/:key',
    component: VerifyComponent,
    canActivate: [AuthenticationGuard],
  }
  
  ,
  {
    path: 'user/verify/password',
    component: VerifyComponent,
    canActivate: [AuthenticationGuard],
  },



  {
    path: 'profile/:uid',
    component: ProfileComponent,
    canActivate: [AuthenticationGuard],
  },
  { path: 'register', component: RegisterComponent },
  //{ path: 'purchase', component: PurchaseRequastAdminComponent },
  {
    path: 'stockrequest',
    loadChildren: () =>
      import('./stockitemrequest/stockitemrequest.module').then(
        (m) => m.StockitemrequestModule
      ),
  },
  {
    path: 'assert',
    loadChildren: () =>
      import('./assert/assert.module').then(
        (m) => m.AssertModule),
  },




  {
    path: 'externaltransfer',
    loadChildren: () =>
      import('./assert/externaltransfer/externaltransfer.module').then(
        (m) => m.ExternaltransferModule),
  },





  {
    path: 'checks',
    loadChildren: () =>
      import('./assert/checks/checks.module').then(
        (m) => m.ChecksModule),
  },

  {
    path: 'audit',
    loadChildren: () =>
      import('./itaudit/itaudit.module').then(
        (m) => m.ITAUDITModule),
  },

  
  {
    path: 'assettransfer',
    component: AssettransferComponent,
    canActivate: [AuthenticationGuard],
  }  ,


    
  {
    path: 'assertdepression',
    component: AssertdepressionComponent,
    canActivate: [AuthenticationGuard],
  },

      
  {
    path: 'externaltransfer',
    component:ExternaltransferComponent,
    canActivate: [AuthenticationGuard],
  },
     
  {
    path: 'officeb',
    component:OfficebComponent,
    canActivate: [AuthenticationGuard],
  },



 
     
  {
    path: 'management',
    component:ManagementComponent,
    canActivate: [AuthenticationGuard],
  },




 
     
  {
    path: 'masterregister',
    component:MasterregisterComponent,
    canActivate: [AuthenticationGuard],
  },
 
  {
    path: 'HeadProcurementAuthorisationComponent',
    component: HeadProcurementAuthorisationComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'HeadProcurementAuthorisation',
    component: HeadProcurementAuthorisationComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'minutes',
    component: MinutesComponent,
    canActivate: [AuthenticationGuard],
  },



  {
    path: 'HeadProcurementAuthorisation/new',
    component: NewHeadProcurementAuthorisationComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'procurement',
    loadChildren: () =>
      import('./procurement/procurement-module').then(
        (m) => m.ProcurementModule
      ),
  },



  {
    path: 'stockitemrequest',
    loadChildren: () =>
      import('./stockitemrequest/stockitemrequest.module').then(
        (m) => m.StockitemrequestModule
      ),
  },













  
  {
    path: 'purchase',
    loadChildren: () =>
      import('./purchase/purchase.module').then((m) => m.PurchaseModule),
  },

  {
    path: 'centralinventoryictissue',
    loadChildren: () =>
      import('./issues/issues.module').then((m) => m.IssuesModule),
  },

  {
    path: 'employees',
    loadChildren: () =>
      import('./employees/employees.module').then((m) => m.EmployeesModule),
  },
  {
    path: 'fleet',
    loadChildren: () =>
      import('./fleet/fleet.module').then((m) => m.FleetModule),
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./inventory/inventory.module').then((m) => m.InventoryModule),
  },

  {
    path: 'Statistics',
    loadChildren: () =>
      import('./statistics/statistics.module').then((m) => m.StatisticsModule),
  }

 

  ,

  {
    path: 'officeb',
    loadChildren: () =>
      import('./assert/officeb/officeb.module').then((m) => m.OfficebModule),
  }

  ,


  
  
  {
    path: 'issued',
    loadChildren: () =>
      import('./issued/issued.module').then((m) => m.issuedModule),
  },
  {
    path: 'finance',
    loadChildren: () =>
      import('./issued/issued.module').then((m) => m.issuedModule),
  },
  {
    path: 'stats',
    loadChildren: () =>
      import('./issued/issued.module').then((m) => m.issuedModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
