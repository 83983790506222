import { Component } from '@angular/core';

@Component({
  selector: 'app-externaltransfer',
  templateUrl: './externaltransfer.component.html',
  styleUrls: ['./externaltransfer.component.css']
})
export class ExternaltransferComponent {

}
