import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, BehaviorSubject, map, startWith, catchError, of, switchMap } from 'rxjs';
import { DataState } from 'src/app/enum/datastate.enum';
import { AccountType, VerifySate } from 'src/app/interface/appstates';
import { User } from 'src/app/interface/user';
import { UserService } from 'src/app/service/user.service';
import { VerifyService } from './verify.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  verifyState: Observable<any>;
  private userSubject = new BehaviorSubject<User>(null!);
  user$ = this.userSubject.asObservable();
  private isLoadingSubject = new BehaviorSubject<any>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  readonly DataState = DataState;
  private readonly ACCOUNT_KEY: string = 'key';
state: any;
key: any;
  constructor(
    private activatedRoute: ActivatedRoute, 
    private verifyService: VerifyService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.key = this.activatedRoute.snapshot.queryParamMap.get('key');
    this.state = this.activatedRoute.snapshot.queryParamMap.get('state');
    console.log(this.state);
    console.log(this.key);
    if (!this.key||!this.state) {
      this.router.navigate(['/login']);
      return;
    }
    // this.verifyState$ = this.activatedRoute.paramMap.pipe(
    //   switchMap((params: ParamMap) => { 
    //     console.log(window.location.href);     
    //     const type: AccountType = this.getAccountType(window.location.href);
    //     return this.userService.verify$(params.get(this.ACCOUNT_KEY), type)
    //       .pipe(
    //         map((response:any) => {
    //           console.log(response);
    //           type === 'password' ? this.userSubject.next(response.data.user) : null;
    //           return { type, title: 'Verified!', dataState: DataState.LOADED, message: response.message, verifySuccess: true };
    //         }),
    //         startWith({ title: 'Verifying...', dataState: DataState.LOADING, message: 'Please wait while we verify the information', verifySuccess: false }),
    //         catchError((error: string) => {
    //           return of({ title: error, dataState: DataState.ERROR, error, message: error, verifySuccess: false })
    //         })
    //       )
    //   })
    // );
  }

  renewPassword(resetPasswordform: NgForm): void {
    // this.isLoadingSubject.next(true);
    console.log(resetPasswordform.value);
    this.verifyService.resetPassword({ token: this.key, password: resetPasswordform.value.password, confirmPassword: resetPasswordform.value.confirmPassword })
    
    .subscribe(
      response => {
        console.log(response.data);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: response.message, life: 3000 });
        this.router.navigate(['/login']);
      },
      error => {
        console.error('Error updating Password:', error);
       //  this.toastr.error(error?.data?.message, 'Failed to update.');
       this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail:error, life: 3000 });

      });
      
    // .pipe(
      //   map((response:any)=> {
      //     console.log(response);
      //     this.isLoadingSubject.next(false);
      //     return { type: 'account' as AccountType, title: 'Success', dataState: DataState.LOADED, message: response.message, verifySuccess: true };
      //   }),
      //   startWith({ type: 'password' as AccountType, title: 'Verified!', dataState: DataState.LOADED, verifySuccess: false }),
      //   catchError((error: string) => {
      //     this.isLoadingSubject.next(false);
      //     return of({ type: 'password' as AccountType, title: 'Verified!', dataState: DataState.LOADED, error, verifySuccess: true })
      //   })
      // )
  }

  private getAccountType(url: string): AccountType {
    return url.includes('password') ? 'password' : 'account';
  }

}

//link to reset password
