<p-toast></p-toast>
<app-sidebar></app-sidebar>
<section class="home-section">


  <!-- nav bar -->
  <nav class="navbar navbar-expand-md breadcrumb">
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item-left">

        </li>

        <li class="nav-item move-right mr-3">
          <a class="nav-link" data-toggle="tab" href="#profile">
            Welcome, <i class="fa fa-user"></i>
          </a>
        </li>
      </ul>

    </div>
  </nav>

  <div class="home-content">
    <div class="container-fluid">
  
  <p-table [value]="masters" [rowsPerPageOptions]="[5, 10, 20]">
    <ng-template pTemplate="header">
      <tr>
        <!-- <th>ID</th> -->
        <th>Asset Description</th>
        <th>Current Year</th>
        <th>Previous Year</th>
        <th>Surplus</th>
        <th>Deficiency</th>
        <th>Remarks</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-check>
      <tr>
        <!-- <td>{{ check.id }}</td> -->
        <td>{{ check.name }}</td>
        <td>{{ check.currentYearTotal }}</td>
        <td>{{ check.previousYearTotal }}</td>
        <td>{{ (check.currentYearTotal - check.previousYearTotal) < 0 ? '-' : (check.currentYearTotal - check.previousYearTotal) }}</td>
        <td>{{ (check.currentYearTotal - check.previousYearTotal) > 0 ? '-' : (check.currentYearTotal - check.previousYearTotal) }}</td>
        <td>{{ check.remark }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
</section>
