import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/service/menu.service';
import { StatisticsService } from 'src/app/statistics/statistics.service';
interface SubMenuItem {
  title: string;
  path: string;
}
interface MenuItem {
  title: string;
  path: string;
  subMenuItems?: SubMenuItem[];
  roles: string[];
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})

export class SidebarComponent implements OnInit {
  isAssertSubMenuVisible: boolean = false;
  roles: any = localStorage.getItem('roles');
  constructor(private menuService: MenuService, private statisticsService: StatisticsService) { }
  role: any;
  menuItems: MenuItem[];
  stationsStat: any[] = [];
  isStatsFetched: boolean;
  ngOnInit(): void {
    // this.role = JSON.parse(localStorage.getItem('roles'));
    // console.log(this.role, '!!!!!!!!!!!!!!!');

    const rolesString = localStorage.getItem('roles');
    if (rolesString) {
      this.role = JSON.parse(rolesString);
    } else {
      this.role = 'SYS_USER';
    }
    this.getAccessibleMenuItems();
  }
  getStatItems() {
    if (!this.isStatsFetched) {
      this.statisticsService.getAllStationStats().subscribe((data: any) => {
        this.stationsStat = data;
      });
      this.isStatsFetched = true;
    }
    return this.stationsStat;
  }
  toggleAssertSubMenu() {
    this.isAssertSubMenuVisible = !this.isAssertSubMenuVisible;
  }
  getAccessibleMenuItems() {
    this.menuItems = this.menuService.menuItems as unknown as MenuItem[];

    //   this.stationsStat.forEach((item) => {

    //     this.menuItems.push({
    //       title: item.name,
    //       path: '#',
    //       subMenuItems: [
    //           { title: 'Assets:', path: item.assets.toString() },
    //           { title: 'Offices:', path: item.offices.toString() }
    //       ],
    //       roles: ['PRINCIPALADMIN', 'ADMIN', 'REGISTRAR']
    //   });
    // });

    return this.menuService.menuItems.filter((item) =>
      item.roles.includes(this.role)
    );

  }

  hasSubMenu(item: any) {
    return item.subMenuItems && item.subMenuItems.length > 0;
  }
}
