import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of, finalize } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { UserService } from "../service/user.service";
import { Key } from '../enum/key.enum';

import { CustomHttpResponse, Profile } from '../interface/appstates';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getAuthToken().pipe(
      switchMap(token => {
        if (token) {
          request = this.addToken(request, token);
        }
        return next.handle(request).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              // Handle token expiration or unauthorized access here
             return this.handle401Error(request, next);
            } else {
              return throwError(error);
            }
          })
        );
      })
    );
  
  
    }
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.refreshToken$().pipe(
      switchMap(() => {
        // Retry the request with new token after successful refresh
        return next.handle(this.addToken(request, this.authService.getToken() || ''));
      }),
      catchError(() => {
        // Handle refresh token failure or logout user
      //  this.authService.logout();
        return throwError('Token refresh failed or user logged out');
      })
    );
  }
  
}

