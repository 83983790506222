import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerifyService {
  [x: string]: any;

  private _baseUrl: string = environment.apiUrl;
  
  constructor(private http: HttpClient) { }
  

  
  public resetPassword(form: { token: number, password: string, confirmPassword: string }): Observable<any> {
    console.log("sending");
    const apiUrl = `${environment.apiUrl}/user/reset/password`;
      return this['http'].post(apiUrl, form);
  }
  




  

}
