import { Component } from '@angular/core';

@Component({
  selector: 'app-head-procurement-authorisation',
  templateUrl: './head-procurement-authorisation.component.html',
  styleUrls: ['./head-procurement-authorisation.component.css']
})
export class HeadProcurementAuthorisationComponent {

}
