
<p-toast></p-toast>

<!-- 

<section *ngIf="(verifyState$ | async) as state" [ngSwitch]="state.dataState">
    <ng-container *ngSwitchCase="DataState.LOADING">
      <div class="container">
        <div class="row justify-content-center my-5">
          <div class="col-md-6" style="margin-top: 50px;">
            <div class="card">
              <div class="card-body">
                <div class="text-center">
                  <h2><i style="margin-right: 5px;" class="bi bi-safe"></i>JSC</h2>
                  <h5 class="card-title mt-4 mb-4">{{ state.title }}</h5>
                  <div style="font-size: 30px;" class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <p class="mt-2" style="font-size: 20px;">{{ state.message }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="DataState.LOADED">
      <div *ngIf="state === 'account'" class="container">
        <div class="row justify-content-center my-5">
          <div class="col-md-6" style="margin-top: 50px;">
            <div class="card">
              <div class="card-body">
                <div class="text-center">
                  <h2><i style="margin-right: 5px;" class="bi bi-safe"></i>JSC</h2>
                  <h5 class="card-title mt-4 mb-4">{{ state.title }}</h5>
                  <i class="bi bi-check-circle-fill" style="font-size: 80px;color: green;"></i>
                  <p class="mt-2" style="font-size: 20px;">{{ state.message }}</p>
                </div>
                <div class="row mb-4">
                  <div class="col d-flex justify-content-center">
                    <a [routerLink]="['/login']">Account Login</a>
                  </div>
                  <div class="col">
                    <a [routerLink]="['/register']" style="color: #0D6EFD; text-decoration: underline; cursor: pointer;">Create another account</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="state == 'password'" class="container">
        <div class="row justify-content-center my-5">
          <div class="col-md-6" style="margin-top: 50px;">
            <div class="card">
              <div class="card-body">
                <div class="text-center">
                  <h2><i style="margin-right: 5px;" class="bi bi-safe"></i>JSC</h2>
                  <h5 class="card-title mt-4 mb-5">Enter New Password</h5>
                </div>
                <div *ngIf="state.error" class="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                  {{ state.error }}
                  <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <form #resetPasswordForm="ngForm" (ngSubmit)="renewPassword(resetPasswordForm)">
                  <div class="form-outline mb-4">
                    <label class="form-label" for="email">New Password</label>
                    <input type="password" ngModel name="password" [disabled]="(isLoading$ | async)" class="form-control" required minlength="2">
                  </div>
                  <div class="form-outline mb-4">
                    <label for="password" class="form-label">Confirm New Password</label>
                    <input type="password" ngModel name="confirmPassword" [disabled]="(isLoading$ | async)" class="form-control" required minlength="2">
                  </div>
                  <div class="d-flex justify-content-center mb-4">
                    <button [disabled]="(isLoading$ | async) || resetPasswordForm.invalid || resetPasswordForm.pristine" type="submit" class="btn btn-primary">
                      <span *ngIf="(isLoading$ | async)" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="margin-right: 5px;"></span>
                      <span *ngIf="(isLoading$ | async)">Loading...</span>
                      <span *ngIf="!(isLoading$ | async)">Update Password</span>
                    </button>
                  </div>
                </form>
                <div class="row mb-4">
                  <div class="col d-flex justify-content-center">
                    <a [ngStyle]="{'pointer-events' : state.dataState === DataState.LOADING || (isLoading$ | async)? 'none' : '' }" [routerLink]="['/login']">Account Login</a>
                  </div>
                  <div class="col">
                    <a [ngStyle]="{'pointer-events' : state.dataState === DataState.LOADING || (isLoading$ | async)? 'none' : '' }" [routerLink]="['/resetpassword']">Forgot password?</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    <ng-container *ngSwitchCase="DataState.ERROR">
      <div class="container">
        <div class="row justify-content-center my-5">
          <div class="col-md-6" style="margin-top: 50px;">
            <div class="card">
              <div class="card-body">
                <div class="text-center">
                  <h2><i style="margin-right: 5px;" class="bi bi-safe"></i>SecureCapita</h2>
                  <h5 class="card-title mt-4 mb-4">{{ state.title }}</h5>
                  <i class="bi bi-exclamation-circle-fill" style="font-size: 80px;color: red;"></i>
                  <p class="mt-2" style="font-size: 20px;">{{ state.message }}</p>
                </div>
                <div class="row mb-4">
                  <div class="col d-flex justify-content-center">
                    <a [routerLink]="['/login']">Account Login</a>
                  </div>
                  <div class="col">
                    <a routerLink="['/register']" >Create another account</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container> -->
    <section>
  <ng-container>
    <div *ngIf="state == 'password'" class="container">
      <div class="row justify-content-center my-5">
        <div class="col-md-6" style="margin-top: 50px;">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <h2><i style="margin-right: 5px;" class="bi bi-safe"></i>JSC</h2>
                <h5 class="card-title mt-4 mb-5">Enter New Password</h5>
              </div>
              <div *ngIf="state.error" class="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                {{ state.error }}
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
              <form #resetPasswordForm="ngForm" (ngSubmit)="renewPassword(resetPasswordForm)">
                <div class="form-outline mb-4">
                  <label class="form-label" for="email">New Password</label>
                  <input type="password" ngModel name="password" [disabled]="(isLoading$ | async)" class="form-control" required minlength="2">
                </div>
                <div class="form-outline mb-4">
                  <label for="password" class="form-label">Confirm New Password</label>
                  <input type="password" ngModel name="confirmPassword" [disabled]="(isLoading$ | async)" class="form-control" required minlength="2">
                </div>
                <div class="d-flex justify-content-center mb-4">
                  <button [disabled]="(isLoading$ | async) || resetPasswordForm.invalid || resetPasswordForm.pristine" type="submit" class="btn btn-primary">
                    <span *ngIf="(isLoading$ | async)" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="margin-right: 5px;"></span>
                    <span *ngIf="(isLoading$ | async)">Loading...</span>
                    <span *ngIf="!(isLoading$ | async)">Update Password</span>
                  </button>
                </div>
              </form>
              <div class="row mb-4">
                <div class="col d-flex justify-content-center">
                  <a [ngStyle]="{'pointer-events' : state.dataState === DataState.LOADING || (isLoading$ | async)? 'none' : '' }" [routerLink]="['/login']">Account Login</a>
                </div>
                <div class="col">
                  <a [ngStyle]="{'pointer-events' : state.dataState === DataState.LOADING || (isLoading$ | async)? 'none' : '' }" [routerLink]="['/resetpassword']">Forgot password?</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section> 

<!-- 
  <div *ngIf="state.registerSuccess" class="container">
    <div class="row justify-content-center my-5">
      <div class="col-md-6" style="margin-top: 50px;">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h2><i style="margin-right: 5px;" class="bi bi-safe"></i>jsc admin</h2>
              <h5 class="card-title mt-4 mb-4">Reset Password</h5>
              <i class="bi bi-check-circle-fill" style="font-size: 80px;color: green;"></i>
              <p class="mt-2" style="font-size: 20px;">{{ state.message }}</p>
            </div>
            <div class="row mb-4">
              <div class="col d-flex justify-content-center">
                <a [routerLink]="['/login']">Account Login</a>
              </div>
              <div class="col">
                <a [routerLink]="['/register']"> Create an Account </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->
