import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Masterregister } from './masterregister';

@Injectable({
  providedIn: 'root'
})
export class MasterregisterService {

  private _baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }


  
  getMaster(): Observable<Masterregister[]> {
    return this.http.get<Masterregister[]>(`${environment.apiUrl}/api/v1/masterAssert/report`);
  }








}
