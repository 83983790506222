<p-toast></p-toast>
<app-sidebar></app-sidebar>
<section class="home-section">
  <div class="card ml-5">
    <div class="flex flex-row items-center pb-10 gap-5 pt-5">
      <!-- Buttons and other interactive elements can go here -->
    </div>
    <p-table >
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th>Asset</th>
          <th>Asset Serial</th>
          <th>Confirmed By</th>
          <th>Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-check>
        <tr>
          <td>{{check.id}}</td>
          <td>{{check.asset}}</td>
          <td>{{check.serial}}</td>
          <td>{{check.checkedByEmail}}</td>
          <td>{{ check.updatedDate | date: 'MM/dd/yyyy, h:mm a' }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</section>
