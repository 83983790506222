<p-toast></p-toast>
<app-sidebar></app-sidebar>
<section class="home-section">


  <!-- nav bar -->
  <nav class="navbar navbar-expand-md breadcrumb">
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item-left">

        </li>

        <li class="nav-item move-right mr-3">
          <a class="nav-link" data-toggle="tab" href="#profile">
            Welcome, <i class="fa fa-user"></i>
          </a>
        </li>
      </ul>

    </div>
  </nav>



  <div class="home-content">
    <div class="container-fluid">
      <p-dialog header="Station and Asset Selection" [(visible)]="showRequestForm" [modal]="true"
        [style]="{ width: '30rem' }">
        <form (ngSubmit)="onMoveSubmit()">
          <div class="mb-4">
            <h3 class="text-lg font-semibold mb-2">From:</h3>
            <div class="flex align-items-center gap-3 mb-3">
              <label for="fromStation" class="font-semibold w-6rem">Station:</label>
              <p-dropdown [options]="stations" [(ngModel)]="fromStation" name="fromStation" placeholder="Select station"
                [style]="{'width':'100%'}" (onChange)="onFromStationChange()"></p-dropdown>
            </div>
            <div *ngIf="fromStation" class="flex align-items-center gap-3 mb-3">
              <label for="fromAsset" class="font-semibold w-6rem">Asset:</label>
              <p-dropdown [filter]="true" [options]="selectionAssets" [(ngModel)]="fromAsset" name="fromAsset"
                placeholder="Select asset" [style]="{'width':'100%'}"></p-dropdown>
            </div>

          </div>

          <div class="mb-4">
            <h3 class="text-lg font-semibold mb-2">To:</h3>
            <div class="flex align-items-center gap-3 mb-3">
              <label for="toStation" class="font-semibold w-6rem">Station:</label>
              <p-dropdown [options]="stations" [(ngModel)]="toStation" name="toStation" placeholder="Select station"
                [style]="{'width':'100%'}" (onChange)="onToStationChange()"></p-dropdown>
            </div>
            <div *ngIf="toStation" class="flex align-items-center gap-3 mb-3">
              <label for="toLocation" class="font-semibold w-6rem">Location:</label>
              <p-dropdown [filter]="true" [options]="selectionOffices" [(ngModel)]="toLocation" name="toLocation"
                placeholder="Select location" [style]="{'width':'100%'}"></p-dropdown>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="moveReason">Reason</label>
            <input type="text" class="p-inputtext-lg" [(ngModel)]="moveReason" placeholder="enter reason" pInputText
              name="moveReason" />

          </div>
          <div class="flex justify-content-end gap-2">
            <p-button label="Cancel" (onClick)="showRequestForm = false" styleClass="p-button-secondary"></p-button>
            <p-button label="Submit" type="submit"></p-button>
          </div>
        </form>
      </p-dialog>
      <!-- Modal for Adding New Request -->
      <div *ngIf="showRequestForm" class="fixed inset-0 flex items-center justify-center z-50">
        <!-- Modal backdrop -->
        <div class="absolute inset-0 bg-black opacity-50"></div>
        <!-- Modal content -->
        <div
          class="bg-white dark:bg-gray-900 shadow-lg rounded-lg overflow-hidden max-w-md mx-auto p-6 relative sm:max-w-lg md:max-w-xl lg:max-w-2xl">
          <button class="absolute top-4 right-4 text-gray-700 dark:text-white"
            (click)="showRequestForm = false">X</button>
          <h3 class="text-xl font-semibold text-gray-700 dark:text-white mb-4">Add New Request</h3>
          <ng-form (ngSubmit)="submitNewRequest($event)" class="space-y-4">
            <div class="mb-4">
              <label for="selectedStationId" class="block text-gray-700 text-sm font-bold mb-2">Select Station:</label>
              <select id="selectedStationId" (ngModelChange)="onStationSelected($event)" name="selectedStationId"
                [(ngModel)]="selectedStationId"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option *ngFor="let station of stations" [value]="station.id">{{ station.name }}</option>
              </select>
              <div *ngIf="stations.length === 0">No stations found.</div>
            </div>
            <div class="mb-4">
              <label for="selectedAssetId" class="block text-gray-700 text-sm font-bold mb-2">Select Asset:</label>
              <select id="selectedAssetId" name="selectedAssetId" [(ngModel)]="selectedAssetId"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option *ngFor="let asset of assets" [value]="asset.id">{{ asset.assetNumber }}</option>
              </select>
            </div>
            <div class="mb-4">
              <label for="selectedLocationId" class="block text-gray-700 text-sm font-bold mb-2">Select
                Location:</label>
              <select id="selectedLocationId" name="selectedLocationId" [(ngModel)]="selectedLocationId"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option *ngFor="let location of locations" [value]="location.id">{{ location.name }}</option>
              </select>
            </div>
            <button (click)="submitNewRequest($event)" type="button"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">
              Submit
            </button>
          </ng-form>
        </div>
      </div>

      <p-table #dt [value]="data" [rows]="6" [paginator]="true"
        [globalFilterFields]="['name', 'country.name', 'representative.name', 'status']"
        [tableStyle]="{ 'min-width': '75rem' }" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between">

            <p-dropdown [options]="stations" [(ngModel)]="selectedStationId" optionLabel="label" optionValue="value"
              (onChange)="onStationChange()" placeholder="Select Station">
            </p-dropdown>


            <span class="p-input-icon-left">
              Assets Move Requests &NonBreakingSpace; <p-button pRipple severity="success" label=" Add New Request"
                icon="pi pi-plus" class="mr-2" (onClick)="toggleRequestForm()" />

            </span>


            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')"
                placeholder="Search..." />
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th class="px-4 py-2">Initiated Date</th>
            <th class="px-4 py-2">Initiated By</th>
            <th class="px-4 py-2">Approved By</th>
            <th class="px-4 py-2">Request Id</th>
            <th class="px-4 py-2">Asset Serial</th>
            <th class="px-4 py-2">Asset Name</th>
            <th class="px-4 py-2">Reason</th>
            <th class="px-4 py-2">Requested Location</th>
            <th class="px-4 py-2">Current Location</th>
            <th class="px-4 py-2">Status</th>
            <th class="px-4 py-2">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="border px-4 py-2" style="white-space: nowrap;">{{ item.date | date:'dd-MM-yyyy' }}</td>
            <td class="border px-4 py-2" style="white-space: nowrap;"> {{ item.initiatedBy ? item.initiatedBy : 'N/A' }}
            </td>
            <td class="border px-4 py-2" style="white-space: nowrap;">{{ item.approvedBy ? item.approvedBy : 'N/A' }}
            </td>
            <td class="border px-4 py-2">{{ item.serial }}</td>
            <td class="border px-4 py-2">{{ item.id }}</td>
            <td class="border px-4 py-2">{{ item.serial }}</td>
            <td class="border px-4 py-2">{{ item.requestedStation }}->{{ item.requestedLocation }}</td>
            <td class="border px-4 py-2">{{ item.reason }}</td>
            <td class="border px-4 py-2">{{ item.currentStation }}->{{ item.currentLocation }}</td>
            <td class="border px-4 py-2">{{ item.status }}</td>
            <td>
              <button *ngIf="item.status === 'PENDING'" type="button"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                [disabled]="item.status!== 'PENDING'" (click)="approveRequest(item.id)">
                Approve
              </button>
              <button *ngIf="item.status === 'PENDING'" type="button"
                class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                [disabled]="item.status!== 'PENDING'" (click)="rejectRequest(item.id)">
                Reject
              </button>
              <button *ngIf="item.status === 'APPROVED'" type="button"
                class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                disabled>
                Approved
              </button>
              <button *ngIf="item.status === 'REJECTED'" type="button"
                class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                disabled>
                Rejected
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>