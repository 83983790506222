import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})


export class MenuService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  roles = ['ROLE_USER', 'ROLE_MANAGER','ROLE_AdminCS' ,'ROLE_ADMIN','ROLE_PRINCIPAL_ADMIN','ROLE_ASSISTANT_ADMIN' ]; // Example roles
  menuItems = [
    {
      title: 'Dashboard',
      path: '/Dashboard',
    roles: ['USER','SYSADMIN','ADMIN'], //i have added 
    },
    // { title: 'Inventory', subMenuItems: [],path: '/inventory', roles: ['SYSADMIN'] },
    // { title: 'Purchase Request',subMenuItems: [], path: '/purchase', roles: ['ADMIN'] },
   
    // {
    //   title: 'Stock Item Request',
    //   path: '/stockitemrequest',
    //   roles: ['ADMIN','SYSADMIN'],
    // }
    
    
    
    // ,
    // {
    //   title: 'Inventory',
    //   path: '/stockitemreques',
    //   roles: ['ADMIN','PRINCIPALADMIN','SYSADMIN'],
    // },

    // {
    //   title: 'AUDIT   TRAIL',
    //   path: '/stockitemrequest',
    //   roles: ['PRINCIPALADMIN','SYSADMIN'],
    // },

    // {
    //   title: 'Procurement',
    //   path: '/stockitemreques',
    //   roles: ['ADMIN','PRINCIPALADMIN','SYSADMIN'],'
    // },
    {
      title: 'Asserts',
      path: '/assert',
      roles: ['PRINCIPALADMIN','ADMIN','SYSADMIN','AdminCS','AUDITOR','SECRETARY','ASSISTANT_ADMIN','ADMINOFFICER'],
      logo: 'assets/images/asserts-logo.png',
    }
    
    ,



    {
      title: 'Checks',
      path: '/checks',
      roles: ['REGISTRAR'],

      logo: 'assets/images/inspection.png',
      
    },



    {
      title: 'Reports',
      path: '/Statistics',
      roles: ['PRINCIPALADMIN','ADMIN','SYSADMIN','REGISTRAR','AUDITOR','SECRETARY','PRINCIPALADMIN','ADMINOFFICER'],
      logo: 'assets/images/reports.png',
    }
   
    ,
    
    {
      title: 'AssistantAdmin',
      path: '/assistantAdmin',
      roles: ['ASSISTANT_ADMIN'],
      logo: 'assets/images/reports.png',
    },



    {
      title: 'INTERNAL TRANSFER',
      path: '/assettransfer',
      roles: ['ASSISTANT_ADMIN', 'ADMINOFFICER'],

      logo: 'assets/images/inspection.png',
      
    },
  
    
    { title: 'Audit', path: '/audit', roles: ['SYSADMIN'] },
    
    { title: 'Inventory', path: '/inventory', roles: ['ROLE_SYSADMIN'] },
    { title: 'Assert Managements', path: '/assert', roles: ['ROLE_SYSADMIN'] },
    { title: 'Finance', path: '/finance', roles: ['ROLE_SYSADMIN'] },
    { title: 'IT Audit', path: '/it', roles: ['ROLE_SYSADMIN'] },
    { title: 'Stats', path: '/stats', roles: ['ROLE_SYSADMIN'] },






  ];
}

//now we want the user to view the dashboard only
//
