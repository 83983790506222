<app-sidebar></app-sidebar>

<section class="home-section">
  <nav>
    <div class="sidebar-button">
      <i class="bx bx-menu sidebarBtn"></i>
      <span class="dashboard">Dashboard</span>
    </div>
    <div class="search-box">
      <input type="text" placeholder="Search..."/>
      <i class="bx bx-search"></i>
    </div>
    <div class="profile-details">

      <i class="bx bx-chevron-down"></i>
      <button (click)="onLogOut()">Logout</button>
    </div>
  </nav>

  <div class="home-content">
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales Cards  -->
      <!-- ============================================================== -->
      <div class="row">
        <!-- Column -->


        <!--

                <a routerLink="/inventory" routerLinkActive="active" class="col-md-6 col-lg-2 col-xlg-3"
                  [class.disabled]="(roles ==  'DEV'  ) ? null : true">


                  <div class="card card-hover">
                    <div class="box bg-info text-center">
                      <h1 class="font-light text-white">
                        <i class="pi pi-money-bill" style="font-size: 2rem"></i>
                      </h1>
                      <h6 class="text-white">Inventory</h6>
                    </div>
                  </div>

                </a> -->


        <!--
        <div
          class="col-md-6 col-lg-4 col-xlg-3"
          routerLink="/purchase"
          routerLinkActive="true"
        >
          <div class="card card-hover">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-truck" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Purchase Request</h6>
            </div>
          </div>
        </div>
         Column -->
        <!-- <div
          class="col-md-6 col-lg-2 col-xlg-3"
          routerLink="/stockitemrequest"
          routerLinkActive="true"
        >
          <div class="card card-hover">
            <div class="box bg-warning text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Stock Item Request</h6>
            </div>
          </div>
        </div> -->


        <!-- <a routerLink="/purchase" routerLinkActive="active" class="col-md-6 col-lg-2 col-xlg-3"
          [class.disabled]="(roles === 'PRINCIPAL_ADMIN' || roles == 'SYSADMIN' ) ? null : true">


          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Purchase Request</h6>
            </div>
          </div>

        </a> -->


        <a routerLink="/assert" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3"
           [hidden]="(roles == 'ADMIN' && roles=='SYSADMIN'    && roles=='PRINCIPALADMIN' && roles=='ASSISTANT_ADMIN'  && roles=='ADMINOFFICER'  && roles=='AUDITOR' && roles=='AdminCS') ">
          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Asset Management</h6>
            </div>
          </div>

        </a>


        <a routerLink="/audit" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
           [hidden]="( roles!== 'AUDITOR'&& roles!=='SYSADMIN' )">
          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">AuditTrail</h6>
            </div>
          </div>
        </a>


        <!-- <a routerLink="/audit" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
        [class.disabled]="(roles!== 'PRINCIPALADMIN' || roles!== 'SECRETARY'||roles!== 'USER')? null : true">
       <div class="card card-hover">
         <div class="box bg-info text-center">
           <h1 class="font-light text-white">
             <i class="pi pi-money-bill" style="font-size: 2rem"></i>
           </h1>
           <h6 class="text-white">AuditTrail</h6>
         </div>
       </div>
     </a> -->

        <a routerLink="/checks" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
           [hidden]="(roles !== 'REGISTRAR' ) ">
          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Checks</h6>
            </div>
          </div>
        </a>


        <!-- <a routerLink="/officeb" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
        [hidden]="(roles !== 'ASSISTANT_ADMIN' ) ">
       <div class="card card-hover">
         <div class="box bg-info text-center">
           <h1 class="font-light text-white">
             <i class="pi pi-money-bill" style="font-size: 2rem"></i>
           </h1>
           <h6 class="text-white">ASSISTANT_ADMIN</h6>
         </div>
       </div>
      </a> -->


        <a routerLink="/masterregister" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
           [hidden]="(roles !== 'PRINCIPALADMIN'  )
 ">

          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Master Assert Register</h6>
            </div>
          </div>
        </a>

        <!--
        <a routerLink="/management" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
        >
        <div class="card card-hover">
         <div class="box bg-info text-center">
           <h1 class="font-light text-white">
             <i class="pi pi-money-bill" style="font-size: 2rem"></i>
           </h1>
           <h6 class="text-white">Project Management</h6>
         </div>
        </div>
        </a> -->


        <a routerLink="/assettransfer" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
           [hidden]="(roles !== 'ADMIN' && roles!=='ASSISTANT_ADMIN'    && roles!=='PRINCIPALADMIN'  && roles!=='ADMINOFFICER'  )
 ">
          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white"> assert Movement</h6>
            </div>
          </div>
        </a>


        <!-- <a routerLink="/assertdepression" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
        [class.disabled]="(roles!== 'PRINCIPALADMIN' || roles!== 'SECRETARY')? null : true">
       <div class="card card-hover">
         <div class="box bg-info text-center">
           <h1 class="font-light text-white">
             <i class="pi pi-money-bill" style="font-size: 2rem"></i>
           </h1>
           <h6 class="text-white">Assert Depression</h6>
         </div>
       </div>
      </a> -->


        <!-- Column -->
        <!-- Column -->
        <!-- <div class="col-md-6 col-lg-3 col-xlg-3 " routerLink="/assert" routerLinkActive="true">


          <div class="card card-hover">
            <div class="box bg-danger text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Assert Managements</h6>
            </div>
          </div>
        </div> -->
        <!-- Column -->

        <!-- <div class="col-md-6 col-lg-2 col-xlg-3">
          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Help Desk</h6>
            </div>
          </div>
        </div> -->


        <a routerLink="/Statistics" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3"
           [hidden]="(roles !== 'ADMIN' && roles!=='REGISTRAR'    && roles!=='SECRETARY'     && roles!=='PRINCIPALADMIN' && roles!=='ADMINOFFICER') ">
          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Reports</h6>
            </div>
          </div>
        </a>


        <!-- <a routerLink="/fleet" routerLinkActive="active" class="col-md-6 col-lg-2 col-xlg-3"
          [class.disabled]="(roles === 'SYSADMIN' || roles == 'PRINCIPAL_ADMIN' ) ? null : true">


          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">fleet</h6>
            </div>
          </div>

        </a> -->

        <!-- <a routerLink="/HR" routerLinkActive="active" class="col-md-6 col-lg-2 col-xlg-3"
          [class.disabled]="(roles === 'PRINCIPAL_ADMIN' || roles == 'SYSADMIN' ) ? null : true">


          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">HR</h6>
            </div>
          </div>

        </a> -->


        <!-- Column -->


      </div>


      <!-- <div class="tab-pane fade show active" id="users">
  <div class="mb-3 float-right">
    <div class="btn-group mr-2">


      <form class="form-inline my-2 my-lg-0 justify-content-center">
        <input name="searchTerm" #searchInput="ngModel" class="form-control mr-sm-2" ngModel
        (ngModelChange)="searchUsers(searchInput.value)" type="search" placeholder="Search users...">
     </form>


  </div> -->
      <!-- [hidden]="!isSYADMIN"  -->

      <div Id="searchusers" *ngIf="getCurrentUserRoleLogin() === 'SYSADMIN'" class="sales-boxes">
        <p-table #dt [value]="users" styleClass="p-datatable-gridlines" [paginator]="true" [rows]="5"
                 [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem' }"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 [rowsPerPageOptions]="[5, 10, 20]" [globalFilterFields]="[
            'firstName',
            'lastName',
            'email',
            'roleName',
            'permissions'
          ]">
          <ng-template pTemplate="caption">
            <div>
              <h5 class="m-0">Search Users</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal(filterValue($event), 'contains')"
                       placeholder="Search..."/>
              </span>
            </div>
          </ng-template>
          <div class="card flex justify-content-center">
            <button pButton pRipple label="Add Station" class="p-button-success"></button>
          </div>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 25%">Name</th>
              <th style="width: 20%">Email</th>
              <th style="width: 25%">Role</th>
              <th style="width: 15%">Permission</th>
              <th style="width: 15%">Station</th>
              <th style="width: 15%">Action</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-product>
            <tr>
              <td>{{ product.firstName }} {{ product.lastName }}</td>
              <td>{{ product.email }}</td>
              <td>{{ product.roleName }}</td>
              <td [innerHTML]="product.permissions.split(',').join('<br>')"></td>
              <td>{{ product.station }}</td>
              <td>
                <p-button icon="pi pi-user-edit" class="small-button"
                          (click)="editUseraction(product)"></p-button>&NonBreakingSpace;

                <p-button icon="pi pi-times" lass="small-button" (click)="deleteUser(product)"
                          styleClass="p-button-rounded p-button-danger font-size:0.5rem"></p-button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
          </ng-template>
          <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
          </ng-template>
        </p-table>
        <!-- look if user is ROLE_SYSADMIN
   it must show user not hidde, is user             you login as ROLE_USER not ROLE_SYSADMIN
   lets test as ROLE_SYSADMIN ok -->
      </div>
    </div>
  </div>

  <p-dialog header="Inspection" [(visible)]="displayStation" [style]="{ width: '50vw' }">
    <form [formGroup]="StationForm" (ngSubmit)="onSubmitStation()">
      <div class="container">
        <div class="pform row g-3">
          <div class="col-sm-8">
            <input type="text" pInputText placeholder="Station" formControlName="station" class="form-control"
                   required/>
          </div>
        </div>

        <button type="submit" pButton label="Submit"></button>
      </div>
    </form>
  </p-dialog>
</section>
