import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, catchError, map, of, startWith } from 'rxjs';
import { DataState } from 'src/app/enum/datastate.enum';

import { UserService } from 'src/app/service/user.service';
import { RegisterState } from '../interface/appstates';
import { NotificationService } from '../service/notification.service';
import { Router } from '@angular/router';
import { NotificationType } from '../enum/notification-type.enum';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent {
  registerState$: Observable<RegisterState> = of({
    dataState: DataState.LOADED,
  });
  readonly DataState = DataState;

  constructor(
    private userService: UserService,
    private router: Router,
    private notifyService: NotificationService
  ) {}

  register(registerForm: NgForm): void {
    console.log(registerForm, 'REG');
    this.registerState$ = this.userService.save$(registerForm.value).pipe(
      map((response) => {
        console.log(response);
        if (response.statusCode == 201) {
          console.log(response.statusCode);

          this.notifyService.notify(
            NotificationType.SUCCESS,
            'User Created Successfully'
          );
        }
        registerForm.reset();
        this.router.navigateByUrl('login');
        return {
          dataState: DataState.LOADED,
          registerSuccess: true,
          message: response.message,
        };
      }),
      startWith({ dataState: DataState.LOADING, registerSuccess: false }),
      catchError((error: string) => {
        return of({
          dataState: DataState.ERROR,
          registerSuccess: false,
          error,
        });
      })
    );
  }

  createAccountForm(): void {
    this.registerState$ = of({
      dataState: DataState.LOADED,
      registerSuccess: false,
    });
  }
}
