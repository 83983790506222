<p-toast></p-toast>

<section *ngIf="(resetPasswordState$ | async) as state" [ngSwitch]="state.dataState">

  <div *ngIf="!state.registerSuccess" class="container">
    <div class="flex justify-center my-10">
      <div class="w-full md:w-1/2 lg:w-1/3 mt-12">
        <div class="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
          <div class="p-6">
            <div class="text-center mb-6">
              <img src="../../assets/img/logo.png" width="200" height="200" alt="Logo" class="mx-auto"/>
              <h5 class="card-title mt-4 mb-5">Reset Password</h5>
            </div>
            <form #resetPasswordForm="ngForm" (ngSubmit)="resetPassword(resetPasswordForm)"
                  class="max-w-sm mx-auto justify-content-center">
              <div class="mb-5">
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                <input type="text" [disabled]="state.dataState === DataState.LOADING" ngModel name="email"
                       id="email" required
                       minlength="2"
                       class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                       placeholder="email@gmail.com" required/>
              </div>
              <button type="submit"
                      [disabled]="state.dataState === DataState.LOADING || resetPasswordForm.invalid || resetPasswordForm.pristine"
                      class="text-white mb-3 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none w-full focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                          <span *ngIf="state.dataState === DataState.LOADING" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true" style="margin-right: 5px;"></span>
                <span *ngIf="state.dataState === DataState.LOADING">Loading...</span>
                <span *ngIf="!(state.dataState === DataState.LOADING)">Send Verification Code</span>
              </button>
              <div *ngIf="state.dataState === DataState.ERROR"
                   class="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                {{ state.error }}
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert"
                        aria-label="Close"></button>
              </div>
            </form>
            <div class="flex flex-col items-center justify-center mb-4 text-black">
              <div class="flex justify-center w-full p-6">
                <button routerLink="/login"
                        class="text-black hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span>Login</span>
                </button>
              </div>
              <div class="flex justify-center w-full text-center">
                <button routerLink="/register"
                        class="text-black hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span>Register new Account</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Success screen -->
  <div *ngIf="state.registerSuccess" class="container">
    <div class="flex justify-center my-10">
      <div class="w-full md:w-1/2 lg:w-1/3 mt-12">
        <div class="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
          <div class="p-6">
            <div class="text-center mb-6">
              <img src="../../assets/img/logo.png" width="200" height="200" alt="Logo" class="mx-auto"/>
              <div id="alert-border-3" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:bg-gray-800 dark:border-green-800" role="alert">
                <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <div class="ms-3 text-sm font-medium">
                 Please Check your email inbox for password reset link and <a href="/login" class="font-semibold underline hover:no-underline"> login </a> to continue.
                </div>
                <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                </button>
              </div>
              <i class="bi bi-check-circle-fill" style="font-size: 80px;color: green;"></i>
              <p class="mt-2" style="font-size: 20px;">{{ state.message }}</p>
            </div>
            <div class="row mb-4">
              <div class="col d-flex justify-content-center">
                <a [routerLink]="['/login']">Account Login</a>
              </div>
              <div class="col">
                <a [routerLink]="['/register']"> Create an Account </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
