export enum Role {
  User = 'USER',
  Admin = 'ADMIN',
  MANAGER = "SYSADMIN",
  SUPER_ADMIN = "PRINCIPAL_ADMIN",
  STORESMANAGER = "ADMIN",
  STOREASSISTANCE = "ADMIN",
  ADMINMANAGER = "SYSADMIN",
  
}
